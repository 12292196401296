import React from 'react'
import styled from 'styled-components'
import variables from '../../styles/variables'

const Svg = styled.svg`
  display: block;
  position: absolute;
  top: -64px;
  right: 0;
  width: 68.35px;
  height: 68.35px;
  @media ${variables.device.tabletXL} {
    width: 100px;
    height: 100px;
    right: -120px;
  }
`

export default function Email() {
  return (
    <Svg width='136' height='136' viewBox='0 0 136 136' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_155_1848)'>
        <path
          d='M44.5143 53.7785C44.1745 53.5941 43.9191 53.2857 43.8012 52.9176C43.6834 52.5494 43.7122 52.15 43.8816 51.8026C44.0511 51.4552 44.3481 51.1866 44.7108 51.0528C45.0735 50.9191 45.4738 50.9305 45.8282 51.0847C55.6888 55.424 65.5136 60.764 75.0167 65.9293C77.8436 67.4643 80.6537 68.9832 83.4472 70.4859L83.4966 70.5123C84.995 71.3152 86.6999 72.3194 88.3497 73.2907C90.4233 74.5151 92.5674 75.7773 94.4011 76.6997C94.7563 76.8792 95.0257 77.1924 95.1499 77.5704C95.2741 77.9485 95.2431 78.3604 95.0637 78.7156C94.8842 79.0707 94.571 79.3401 94.1929 79.4643C93.8149 79.5886 93.403 79.5575 93.0478 79.3781C91.1317 78.4082 88.9436 77.1224 86.8268 75.8748C85.1303 74.8762 83.5253 73.9312 82.0824 73.1581L81.3226 72.751C78.7576 71.3767 76.1781 69.9743 73.5823 68.5642C64.1336 63.4223 54.3651 58.1148 44.6216 53.8258C44.5851 53.8119 44.5492 53.7961 44.5143 53.7785Z'
          fill='black'
        />
        <path
          d='M76.67 109.14L76.6594 109.135C76.4865 109.04 76.3339 108.913 76.2103 108.759C76.0867 108.606 75.9945 108.43 75.9391 108.241C75.8836 108.052 75.8659 107.854 75.8869 107.658C75.908 107.462 75.9675 107.272 76.0619 107.099C78.9061 101.894 81.9541 96.3496 84.9864 90.836C87.5133 86.2384 90.0297 81.6623 92.4048 77.3226C92.5946 76.9755 92.9139 76.7175 93.293 76.6047C93.6722 76.4919 94.0806 76.5334 94.4293 76.7202L94.4416 76.7269C94.6144 76.8215 94.7668 76.9492 94.8902 77.1028C95.0136 77.2564 95.1055 77.4327 95.1608 77.6218C95.216 77.8109 95.2335 78.009 95.2121 78.2049C95.1908 78.4007 95.1311 78.5904 95.0364 78.7632C92.66 83.1034 90.1444 87.6821 87.6143 92.2815C84.5847 97.7919 81.5377 103.335 78.6944 108.538C78.5046 108.885 78.1854 109.143 77.8062 109.256C77.427 109.369 77.0187 109.327 76.67 109.14Z'
          fill='black'
        />
        <path
          d='M76.6707 109.141C76.6118 109.11 76.5548 109.075 76.5001 109.036C71.597 105.673 66.4848 102.626 61.1946 99.9124L58.9503 98.6975C57.1926 97.7432 55.4753 96.7834 53.7682 95.828C49.6241 93.5112 45.7089 91.3239 41.4382 89.3091C39.9701 88.6167 38.5555 87.886 37.1859 87.1794C34.8268 85.9044 32.3973 84.7641 29.9092 83.764C29.5376 83.6219 29.2377 83.3381 29.0754 82.9748C28.9131 82.6116 28.9018 82.1988 29.0438 81.8272C29.1859 81.4556 29.4698 81.1557 29.833 80.9934C30.1962 80.8311 30.609 80.8197 30.9806 80.9618C33.5724 82.0026 36.1035 83.1884 38.5621 84.5133C39.9075 85.2081 41.2992 85.9265 42.7178 86.5947C47.0853 88.6533 51.2263 90.9696 55.2317 93.2086C56.9291 94.1589 58.6359 95.113 60.3821 96.0611L62.6202 97.2728C68.0258 100.046 73.2487 103.163 78.2568 106.602C78.5661 106.825 78.7791 107.157 78.8526 107.531C78.9262 107.904 78.8547 108.292 78.6527 108.615C78.4508 108.938 78.1334 109.172 77.7652 109.27C77.3969 109.368 77.0053 109.321 76.6698 109.141L76.6707 109.141Z'
          fill='black'
        />
        <path
          d='M29.7339 83.6888L29.6916 83.6661C29.3471 83.4673 29.0957 83.1399 28.9926 82.7557C28.8895 82.3716 28.9432 81.9622 29.1419 81.6177C32.6501 75.5377 35.3025 70.5195 37.4913 65.8224C38.6885 63.2555 39.8044 60.7766 40.9251 58.289C41.8835 56.1636 42.8429 54.0321 43.8583 51.8341C43.9406 51.6541 44.0576 51.4922 44.2028 51.3577C44.3479 51.2233 44.5182 51.1188 44.7038 51.0504C44.8895 50.982 45.0868 50.951 45.2845 50.9592C45.4822 50.9674 45.6763 51.0146 45.8556 51.0981C46.0362 51.1785 46.1988 51.2943 46.3339 51.4387C46.469 51.583 46.5738 51.7529 46.6421 51.9384C46.7105 52.1239 46.7409 52.3213 46.7318 52.5187C46.7226 52.7162 46.6739 52.9098 46.5887 53.0882C45.5789 55.2779 44.6188 57.4 43.6678 59.518C42.5424 62.0167 41.4203 64.507 40.2187 67.0863C37.9923 71.8642 35.3002 76.9565 31.7456 83.1125C31.5514 83.4517 31.2326 83.7017 30.8568 83.8094C30.4811 83.917 30.0782 83.8737 29.7339 83.6888Z'
          fill='black'
        />
        <path
          d='M60.7117 82.7869C60.4723 82.6592 60.2724 82.4686 60.1336 82.2355C54.8109 73.3437 49.503 63.8336 43.8934 53.1566C43.7123 52.8049 43.6775 52.3958 43.7967 52.0185C43.9158 51.6411 44.1792 51.3262 44.5294 51.1422C44.8797 50.9582 45.2885 50.92 45.6668 51.036C46.0451 51.152 46.3622 51.4127 46.5491 51.7615C52.1324 62.3902 57.4169 71.855 62.7066 80.6962C62.8752 80.9778 62.9468 81.3069 62.9104 81.6331C62.874 81.9593 62.7316 82.2645 62.5051 82.502C62.2785 82.7395 61.9804 82.8962 61.6563 82.948C61.3322 82.9997 61 82.9438 60.7108 82.7887L60.7117 82.7869Z'
          fill='black'
        />
        <path
          d='M60.7113 82.7874C60.442 82.6444 60.2226 82.4227 60.0825 82.1519C59.9424 81.8811 59.8881 81.5741 59.9269 81.2717C59.9657 80.9692 60.0958 80.6858 60.2998 80.4592C60.5037 80.2325 60.7719 80.0734 61.0686 80.0031L61.4429 79.9143C66.8111 78.5079 72.2714 77.4794 77.7835 76.8366C83.1079 76.3523 88.4604 76.2555 93.7989 76.5468C94.1963 76.5669 94.5694 76.744 94.8363 77.0392C95.1031 77.3344 95.2417 77.7234 95.2217 78.1209C95.2016 78.5183 95.0245 78.8914 94.7293 79.1582C94.4341 79.4251 94.045 79.5637 93.6476 79.5437C88.4554 79.2585 83.2494 79.3508 78.0706 79.8199C72.6973 80.4544 67.3746 81.462 62.1411 82.8354L61.7664 82.925C61.4097 83.0096 61.0343 82.9606 60.7113 82.7874Z'
          fill='black'
        />
        <path
          d='M29.7337 83.6878C29.4842 83.5538 29.2778 83.352 29.1383 83.1055C28.9988 82.8591 28.9319 82.5783 28.9453 82.2954C28.9587 82.0126 29.0519 81.7393 29.2141 81.5072C29.3763 81.2751 29.6009 81.0937 29.8619 80.9838C37.2202 77.8924 47.3024 73.6512 55.7272 71.1312C55.917 71.0709 56.1169 71.0489 56.3152 71.0667C56.5136 71.0844 56.7064 71.1414 56.8825 71.2345C57.0586 71.3275 57.2144 71.4547 57.3408 71.6086C57.4672 71.7624 57.5617 71.94 57.6188 72.1308C57.6759 72.3215 57.6944 72.5218 57.6732 72.7198C57.6521 72.9178 57.5918 73.1097 57.4957 73.2841C57.3997 73.4586 57.2699 73.6122 57.1138 73.7359C56.9578 73.8597 56.7787 73.9511 56.5869 74.0049C48.3174 76.4765 38.3236 80.6777 31.0264 83.744C30.8204 83.8317 30.5974 83.8722 30.3737 83.8625C30.15 83.8528 29.9313 83.7931 29.7337 83.6878Z'
          fill='black'
        />
        <path
          d='M76.6691 109.141C76.4751 109.037 76.3063 108.892 76.1747 108.716C76.043 108.54 75.9518 108.337 75.9073 108.121C73.8514 98.1378 72.1041 87.6061 71.1157 79.2236C71.0925 79.0278 71.108 78.8294 71.1615 78.6396C71.2149 78.4499 71.3052 78.2725 71.4272 78.1176C71.5492 77.9628 71.7005 77.8335 71.8725 77.7371C72.0445 77.6407 72.2338 77.5791 72.4296 77.5559C72.6253 77.5327 72.8238 77.5482 73.0135 77.6017C73.2033 77.6551 73.3807 77.7454 73.5355 77.8674C73.8483 78.1138 74.0503 78.4744 74.0973 78.8698C75.075 87.1705 76.8066 97.611 78.8501 107.509C78.9077 107.789 78.8845 108.079 78.7832 108.345C78.6819 108.612 78.5067 108.844 78.2782 109.015C78.0496 109.185 77.7771 109.287 77.4927 109.308C77.2083 109.33 76.9237 109.269 76.6724 109.135L76.6691 109.141Z'
          fill='black'
        />
        <path
          d='M72.8726 58.6309C72.5363 58.4517 72.2808 58.1512 72.158 57.7904C72.0353 57.4296 72.0544 57.0356 72.2116 56.6885C74.7993 50.9651 77.7276 45.3663 80.558 39.9522C80.6493 39.7776 80.7741 39.6228 80.9252 39.4964C81.0764 39.3701 81.2509 39.2747 81.4389 39.2159C81.6269 39.157 81.8246 39.1357 82.0209 39.1533C82.2171 39.1708 82.4079 39.2268 82.5825 39.3181L82.5957 39.3252C82.9442 39.5119 83.2049 39.8287 83.3211 40.2067C83.4373 40.5846 83.3996 40.9932 83.2161 41.3435C80.4051 46.7192 77.4976 52.2792 74.9456 57.9253C74.8645 58.1054 74.7486 58.2677 74.6046 58.4029C74.4606 58.5382 74.2913 58.6436 74.1064 58.7132C73.9215 58.7828 73.7247 58.8152 73.5273 58.8086C73.3299 58.8019 73.1357 58.7563 72.9559 58.6744L72.8726 58.6309Z'
          fill='black'
        />
        <path
          d='M84.4547 64.0715C84.2261 63.9488 84.0332 63.7688 83.8949 63.5492C83.7566 63.3296 83.6777 63.0779 83.6658 62.8186C83.6539 62.5594 83.7095 62.3015 83.8271 62.0702C83.9447 61.8388 84.1203 61.642 84.3367 61.4988C87.3138 59.5293 90.3943 57.6504 93.3746 55.8336L93.8139 55.5663C94.1533 55.3666 94.5576 55.3084 94.9395 55.4041C95.3214 55.4999 95.6504 55.742 95.8554 56.0782C96.0604 56.4144 96.1249 56.8177 96.0352 57.2011C95.9454 57.5845 95.7085 57.9172 95.3755 58.1274L94.9358 58.3955C91.98 60.1983 88.9231 62.0627 85.9921 64.0013C85.7661 64.1508 85.5038 64.2362 85.2331 64.2486C84.9624 64.261 84.6934 64.1998 84.4547 64.0715Z'
          fill='black'
        />
        <path
          d='M56.2446 50.1999C56.0213 50.0805 55.832 49.9064 55.6945 49.6938C55.557 49.4812 55.4757 49.2371 55.4584 48.9845C55.2377 46.0085 54.9701 42.0804 54.8255 38.0731C54.8111 37.6754 54.9552 37.2883 55.2262 36.9969C55.4972 36.7056 55.8729 36.5338 56.2706 36.5194C56.6682 36.505 57.0553 36.6491 57.3467 36.9201C57.6381 37.1911 57.8099 37.5668 57.8243 37.9644C57.9672 41.9221 58.2335 45.8165 58.4491 48.766C58.468 49.0334 58.415 49.3009 58.2955 49.5408C58.176 49.7807 57.9944 49.9842 57.7696 50.1301C57.5448 50.276 57.285 50.3591 57.0172 50.3706C56.7494 50.382 56.4835 50.3216 56.247 50.1955L56.2446 50.1999Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_155_1848'>
          <rect width='100' height='100' fill='white' transform='translate(47.5391 0.313843) rotate(28.1816)' />
        </clipPath>
      </defs>
    </Svg>
  )
}
